var App = {
    initUpCTA: function () {
        var top_show = 150; // В каком положении полосы прокрутки начинать показ кнопки "Наверх"
        var delay = 1000; // Задержка прокрутки
        $( document ).ready( function () {
            $( window ).scroll( function () { // При прокрутке попадаем в эту функцию
                /* В зависимости от положения полосы прокрукти и значения top_show, скрываем или открываем кнопку "Наверх" */
                if ( $( this ).scrollTop() > top_show )
                    $( '.button-to-top' ).fadeIn();
                else
                    $( '.button-to-top' ).fadeOut();
            } );
            $( '.button-to-top' ).click( function () { // При клике по кнопке "Наверх" попадаем в эту функцию
                /* Плавная прокрутка наверх */
                $( 'body, html' ).animate( {
                    scrollTop: 0
                }, delay );
            } );
        } );
    },
    initScripts: function () {

        if (document.querySelector('.agents-carousel')) {
            $('.agents-carousel').slick({
                dots: true,
                infinite: false,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                            dots: true
                        }
                    }
                ]
            });
        }

        $( '.autocomplete' ).styler( { 
            selectSearch: 'true',  
            selectSearchLimit: '4',
            selectSearchNotFound: 'Not Found',
            selectSearchPlaceholder: 'Search...',
        } ); 
        $( 'input, select' ).styler( {
            selectSearch: 'false',
            selectSearchLimit: '32',
            locale: 'en',
            locales: {
                'en': {
                  filePlaceholder: 'No file selected',
                  fileBrowse: 'Browse...',
                  fileNumber: 'Selected files: %s',
                  selectPlaceholder: 'Select...',
                  selectSearchNotFound: 'No matches found',
                  selectSearchPlaceholder: 'Search...'
                }
            }
        } );
        if ( $( "[data-toggle='tooltip']" ).length > 0 ) {
            $( '[data-toggle="tooltip"]' ).tooltip( {
                html: true
            } );
        }
    },


    initVideofile: function () {
        if ( $( '#video-block' ).length > 0 ) {
            var myPlayer = videojs( 'video-block' );
            $( 'body' ).on( 'click', '.play-btn', function () {
                if (myPlayer.paused()) {
                    myPlayer.play();
                }
                else {
                    myPlayer.pause();
                }
            });
        }
    },

    modalPause: function(){
        $('body').on('hidden.bs.modal', '.video-1', function (e) {
           var myPlayer = videojs( 'video-blockone' );
           if (!myPlayer.paused()) {
                myPlayer.pause();
            }
        });
         $('body').on('hidden.bs.modal', '.video-2', function (e) {
           var myPlayer = videojs( 'video-blocktwo' );
           if (!myPlayer.paused()) {
                myPlayer.pause();
            }
        });
         $('body').on('hidden.bs.modal', '.video-3', function (e) {
           var myPlayer = videojs( 'video-blockthree' );
           if (!myPlayer.paused()) {
                myPlayer.pause();
            }
        });
    },

    accordionClose: function () {
        $('#accordion' ).on('show.bs.collapse', function () {
            $('#accordionTwo .in').collapse('hide');
        });
        $('#accordionTwo' ).on('show.bs.collapse', function () {
            $('#accordion .in').collapse('hide');
        });
    },

    addPosters: function() {
        if (document.getElementById('video-blockone') && document.getElementById('video-blocktwo') && document.getElementById('video-blockthree')) {
            videojs('video-blockone').poster('themes/assets/videos/testi2.jpg');
            videojs('video-blocktwo').poster('themes/assets/videos/testi1.jpg');
            videojs('video-blockthree').poster('themes/assets/videos/testi3.jpg');
        }
    },

    init: function ( ) {
        this.initUpCTA();
        this.initScripts();
        this.initVideofile();
        this.accordionClose();
        this.modalPause();
        this.addPosters();
    }
};
jQuery( document ).ready( function ( ) {
    App.init( );
} );
window.jQuery = window.$ = jQuery;

document.addEventListener("DOMContentLoaded", function() {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
    var lazyBackground= [].slice.call(document.querySelectorAll(".lazy-background"));
    var active = false;
    var activeBg = false;

    var lazyLoad = function() {
        if (active === false) {
            active = true;

            setTimeout(function() {
                lazyImages.forEach(function(lazyImage) {
                    if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImage.classList.remove("lazy");

                        lazyImages = lazyImages.filter(function(image) {
                            return image !== lazyImage;
                        });

                        if (lazyImages.length === 0) {
                            document.removeEventListener("scroll", lazyLoad);
                            window.removeEventListener("resize", lazyLoad);
                            window.removeEventListener("orientationchange", lazyLoad);
                        }
                    }
                });

                active = false;
            }, 200);
        }
    };

    var lazyBackgroundFn = function() {
        if (activeBg === false) {
            activeBg = true;

            setTimeout(function() {
                lazyBackground.forEach(function(lazyImage) {
                    if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {

                        lazyImage.style.backgroundImage = 'url(' + lazyImage.dataset.bg + ')';
                        lazyImage.classList.remove("lazy-background");

                        lazyBackground = lazyBackground.filter(function(image) {
                            return image !== lazyImage;
                        });

                        if (lazyBackground.length === 0) {
                            document.removeEventListener("scroll", lazyBackgroundFn);
                            window.removeEventListener("resize", lazyBackgroundFn);
                            window.removeEventListener("orientationchange", lazyBackgroundFn);
                        }
                    }
                });

                activeBg = false;
            }, 200);
        }
    };

    document.addEventListener("scroll", lazyLoad);
    window.addEventListener("resize", lazyLoad);
    window.addEventListener("orientationchange", lazyLoad);

    document.addEventListener("scroll", lazyBackgroundFn);
    window.addEventListener("resize", lazyBackgroundFn);
    window.addEventListener("orientationchange", lazyBackgroundFn);
});
